<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>

    <div id="app-content">
      <div class="page-item red" v-if="shoptext.verifyAdvise">
        原因：{{ shoptext.verifyAdvise }}
      </div>

      <!--    商铺信息 (订单)-->
      <div class="page-item">
        <div class="shop-goto" @click="goShop()">
          <span class="f36 left ">
            <span class="page-icon2 shop"></span>
            <strong class="txt-ellipsis shopName margin-l1">{{ shoptext.shopName }}</strong>
            <!-- <i class="el-icon-arrow-right"></i> -->
          </span>
          <span class="goto-img ">
            <img v-if="shoptext.channelOutId === 'C004' || shoptext.channelOut === 'C004'"
              src="../../assets/home/elm.png">
            <img v-if="shoptext.channelOutId === 'C003' || shoptext.channelOut === 'C003'" src="../../assets/home/mt.png">
            <i class="el-icon-arrow-right"></i>

            <!-- <span class="f32 mainColor margin-l1">前往店铺</span> -->
          </span>
        </div>
        <div class="shopInfo">
          <img class="img-box" :src="shoptext.albumPics || shoptext.thumbPics" />
          <div class="flex-1">
            <div><strong>{{ shoptext.spuName || shoptext.spuTitle }}</strong></div>
            <div class="margin-t2">
              <span class="noPlus f32 order">满￥{{ shoptext.amount || shoptext.price }} 返￥{{ realProfit }} </span>
              <!-- <span class="noPlus f32 order margin-l2" v-if="shoptext.commentFlag === 'N'">图文反馈</span> -->
            </div>
            <div class="margin-t2 f34 font-bold red">
              {{ shoptext.commentFlag === 'Y' ? '本单无需品鉴意见' : shoptext.detailTitle }}
            </div>
          </div>
        </div>

        <div class="address">
          <span v-if="shoptext.commentFlag === 'N'">{{ shoptext.detailDesc }}</span>
          <span v-if="shoptext.commentFlag === 'Y'">{{ shoptext.commentDesc }}</span>
        </div>
      </div>

      <div class="fill-number page-item">
        <div class="title mainColor f40">
          <strong><i>01</i> 填写信息</strong>
        </div>
        <div class="f38 margin-tb3">
          <strong>实付金额 (元)</strong>
        </div>
        <el-input placeholder="请填写实付金额" v-enter-money v-model="form.realAmount" maxlength="8"></el-input>
        <div class="f38 margin-tb3">
          <strong>填写订单号</strong>
          <span class="fr mainColor f34" @click="copyOrder()">去复制订单号</span>
        </div>
        <el-input placeholder="请填写订单号" v-model="form.chnlOrderId"></el-input>
      </div>

      <div class="unload-list page-item">
        <div class="title mainColor f40">
          <strong><i>02</i> 上传截图</strong>
        </div>
        <div class="f32 grey">截图中需要包含订单状态、商家名称、订单时间、支付金额、收货信息和订单编号</div>
        <div class="unload-goto">
          <div class="item">
            <div class="img mainDashedBorder" v-if="photoList[0]" @click="checkImg(photoList[0])">
              <img :src="photoList[0]" />
              <van-icon name="cross" @click.stop="delectimg(0)"></van-icon>
            </div>
            <div class="tip mainColor mainDashedBorder" v-else @click="handleImage(0)">
              <van-icon name="photo-o" />
              <div>订单截图</div>
            </div>
            <div class="text" @click="perivewimg('order')">查看示例</div>
          </div>
          <div class="page-icon arrow"></div>

          <div class="item">
            <div class="img mainDashedBorder" v-if="photoList[1]" @click="checkImg(photoList[1])">
              <img :src="photoList[1]" />
              <van-icon name="cross" @click.stop="delectimg(1)"></van-icon>
            </div>
            <div class="tip mainColor mainDashedBorder" v-else @click="handleImage(1)">
              <van-icon name="photo-o" />
              <div>订单截图</div>
            </div>
            <div class="text" @click="perivewimg('order2')">查看示例</div>
          </div>
          <div class="page-icon arrow" :class="shoptext.commentFlag === 'Y' ? 'opacity-0 disabled' : ''"></div>

          <div class="item " :class="shoptext.commentFlag === 'Y' ? 'opacity-0 disabled' : ''">
            <div class="img mainDashedBorder" v-if="photoList[2]" @click="checkImg(photoList[2])">
              <img :src="photoList[2]" />
              <van-icon name="cross" @click.stop="delectimg(2)"></van-icon>
            </div>
            <div class="tip mainColor mainDashedBorder" v-else>
              <van-icon name="photo-o" @click="handleImage(2)" />
              <div>品鉴截图</div>
            </div>
            <div class="text" @click="perivewimg('comment')">查看示例</div>
          </div>
        </div>

        <div class="title mainColor f40 margin-t2">
          <strong><i>03</i> 给商家建议 (选填)</strong>
        </div>
        <div>
          <el-input v-model="form.commitAdvise" type="textarea" :rows="3" placeholder="选填" maxlength="50"
            show-word-limit></el-input>
        </div>
      </div>
      <div class="submitBut" @click="sumbit()">提交</div>
    </div>

    <SelfTry ref="selfTry" />
  </div>
</template>

<script>
import { getlist, uploadPhoto } from '@/api'
import theUploadImg from '../../components/UploadImg'
import { Toast } from 'vant'
import SelfTry from './compents/selfTry.vue'
import { mapState, mapActions } from 'vuex'
import { wxChooseImage } from '@/utils/utils'

export default {
  name: 'screenshot',
  components: {
    SelfTry,
  },
  data() {
    return {
      shoptext: {},
      photoList: ['', '', ''],
      form: {
        trxCode: 'MS104',
        realAmount: '',
        chnlOrderId: ''
      },
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'versions', 'isApp']),
    realProfit() {
      return (
        (this.custMsg.vipPlus === 1
          ? this.shoptext.vipProfit
          : this.shoptext.profit) || this.shoptext.realProfit
      )
    },
  },
  created() {
    if (sessionStorage.getItem('shoptext')) {
      this.shoptext = JSON.parse(sessionStorage.getItem('shoptext'))
      // console.log(this.shoptext)
      this.form.chnlOrderId = this.shoptext.note || ''
      this.form.realAmount = this.shoptext.realAmount || ''
      this.form.logId = this.shoptext.logId || this.$route.query.orderId

      if (this.shoptext.commitPics) {
        this.photoList = JSON.parse(this.shoptext.commitPics)
      }
    }

  },
  methods: {
    ...mapActions('user', ['getOrderNum']),

    // 提交
    async sumbit() {
      // if (this.form.realAmount < this.shoptext.amount) {
      //   this.$toast('实付金额未满足商家要求')
      //   return
      // }

      if (this.form.chnlOrderId === '') {
        this.$toast('请填写订单号')
        return
      }

      let httpPhotos = this.photoList.filter(item => {
        return item.indexOf('http') === 0
      })


      let base64Photos = this.photoList.filter(item => {
        return this.isBase64(item)
      })
      if (base64Photos.length > 0) {
        let toast = Toast.loading({
          duration: 0, // 持续展示
          forbidClick: true,
          message: '图片上传中...',
        })
        await uploadPhoto(base64Photos, '').then((res) => {
          toast.clear()
          if (res.data) {
            httpPhotos.push(...res.data)
          } else {
            this.$toast(res.rspMsg)
          }
        })
      }

      // 订单提交
      if (httpPhotos.length > 0) {
        this.form.pics = JSON.stringify(httpPhotos)
      } else {
        this.form.pics = ''
      }
      if (this.shoptext.note) {
        this.form.oriChnlOrderId = this.shoptext.note
      }
      getlist(this.form).then((res) => {
        if (res.rspMsg) {
          this.$toast(res.rspMsg)
        } else {
          this.getOrderNum()
          this.$toast.success('提交成功')
          this.$router.push('/')
        }
      })
    },
    isBase64(str) {
      return str.indexOf('data:') != -1 && str.indexOf('base64') != -1
    },

    // 删除图片
    delectimg(i) {
      this.photoList.splice(i, 1)
    },
    // 选择图片
    handleImage(index) {
      if (this.versions === 'WeChat') {
        wxChooseImage().then(res => {
          this.photoList.splice(index, 1, res)
        }, () => {
          theUploadImg().then((res) => {
            this.photoList.splice(index, 1, res)
          })
        })
      } else {
        theUploadImg().then((res) => {
          this.photoList.splice(index, 1, res)
        })
      }
    },

    // 预览图片
    checkImg(url) {
      this.$refs.selfTry.getImg(true, url)
    },

    // 查看示例
    perivewimg(type) {
      let map = {
        order: require('../../assets/screenshot/screenshot.jpg'),
        order2: require('../../assets/screenshot/screenshot2.jpg'),
        comment: require('../../assets/screenshot/comment.jpg'),
      }
      let url = map[type]
      this.$refs.selfTry.getImg(true, url)
    },

    goShop() {
      // 非app环境
      if (!this.isApp) {
        if (this.shoptext.channelOutLink) {
          window.location.href = this.shoptext.channelOutLink
        } else {
          var urls = window.encodeURI(this.shoptext.channelOutApplet)
          this.$refs.selfTry.getImg(true, urls)
        }
      }

      // app环境
      if (this.isApp) {
        if (this.shoptext.channelOutLink) {
          uni.postMessage({
            data: {
              type: 'openUrlOnBrowser',
              url: this.shoptext.channelOutLink
            }
          })
        } else {
          this.$toast('请复制店名到店下单~')
        }
      }
    },

    // 去复制订单号
    copyOrder() {
      let mtUrl = 'https://i.meituan.com/brunch/order'
      let emlUrl = 'https://h5.ele.me/order/'

      if (this.shoptext.channelOut === 'C004' || this.shoptext.channelOutId === 'C004') {
        window.location.href = emlUrl
      }
      if (this.shoptext.channelOut === 'C003' || this.shoptext.channelOutId === 'C003') {
        window.location.href = mtUrl
      }
    },
  },
}
</script>

<style lang="less" scoped>
.page-item {
  .title {
    margin-bottom: 2vw;
  }
}

.shopInfo {
  display: flex;

  .img-box {
    width: 21vw;
    height: 21vw;
    object-fit: cover;
    border-radius: 2vw;
    margin-right: 3vw;
  }
}

.address {
  padding: 1vw 2vw;
  border-radius: 4px;
  margin-top: 12px;
  background: #f3f3f3;
  color: #666;
}

.shop-goto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #efefef;

  .left {
    display: flex;
    align-items: center;
  }

  .shopName {
    max-width: 46vw;
    display: inline-block;
  }

  .goto-img>img {
    width: 15px;
    vertical-align: middle;
  }

  .goto-img>i[class='el-icon-arrow-right'] {
    color: #cccccc;
    vertical-align: middle;
  }

  .page-icon2.shop {
    width: 14px;
    height: 13px;
    background-position: -289px -156px;
    transform: scale(1.2);
    margin-right: 1vw;
  }
}

.unload-goto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3vw;

  .item {
    text-align: center;

    img {
      width: 20vw;
      height: 20vw;
      object-fit: contain;
    }
  }

  .item .img {
    width: 20vw;
    height: 20vw;
    position: relative;
    border-radius: 2vw;

    .van-icon {
      position: absolute;
      top: 1vw;
      right: 1vw;
      color: #6794ff;
    }
  }

  .item .tip {
    width: 20vw;
    height: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2vw;
    font-size: 3.8vw;
    margin: 0 auto;

    .van-icon {
      font-size: 10vw;
    }
  }

  .item .text {
    font-size: 3.6vw;
    color: #999;
    margin-top: 2vw;
  }

  .crrow {
    width: 4vw;
    margin: 0 2vw;
  }
}

.page-icon.arrow {
  width: 8px;
  height: 14px;
  background-position: -78px -123px;
  margin-bottom: 4vw;
}
</style>
